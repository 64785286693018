import { CertemyEnvironment } from './environment.interface';
import pjson from '../../package.json';

export const environment: CertemyEnvironment = {
  name: 'Dev',
  release: pjson.version,
  production: false,
  mixpanelToken: '4c5a101ee8de6757349f5e625736332b',
  stripeKey: 'pk_test_NMuH6XKWKXmgrBkN9BZx0LSJ',
  sentryKey: 'https://3f2186d61d3d44ec927fa737571f3d78@sentry-monitoring.certemy.com/13',
  port: '443',
  portAuthz: '443',
  JWT_EXPIRE_TIME: 120,
  CERTEMY_WEBSITE_URL: 'https://certemy.com',
  enableMFA: false,
  GIT_COMMIT: '54513dda2485eed7f37836176c88c3f976c4d054',
  PAYFLOW_URL: 'https://pilot-payflowlink.paypal.com',
  SQUARE_URL: 'https://sandbox.web.squarecdn.com/v1/square.js',
  REFERRAL_URL: 'https://certemy-tracking-qa.surge.sh/scripts/tracking/referral.v1.js',
  useReferral: false,
  exactPaymentPath: 'https://rpm.demo.e-xact.com/payment',
  ePayPaymentPath: 'https://epay2dev.wvsto.com/ePayCustomPage/ePay.aspx',
  showExternalLogin: true,
  googleAuthClientId: '14911028123-jc8dr1mot96ofaohd58jjkgmgigbhq6n.apps.googleusercontent.com',
};
